import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import {
  AdminFindParams,
  AdminUpdateParams,
  AlreadyRegisterParams,
  DefinePasswordParams,
  GetUserParams,
  SetAdditionalInformationParams,
  UpdateParams,
  UserCreateParams,
  UserExistsParams,
} from "@/types/resources/onboard/UserResource";

import ColorGenerator from "@/utils/ColorGenerator";
import { IUserBankAccount, IUserExists, IUserModel } from "@/types/models/User";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

export default class UserResource extends AbstractOnboardResource {
  async userExists({ cpf, email }: UserExistsParams) {
    try {
      const response = await this.api.get({
        route: "/public/user-exists",
        params: {
          cpf,
          email,
        },
      });
      return this.newResourceReturnFactory.create<IUserExists>({
        response: response,
        data: response.data,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<IUserExists>({
        response: e,
        data: false,
      });
    }
  }

  async getUser({ shouldQueue }: GetUserParams) {
    try {
      const response = await this.api.get({ route: "/user", shouldQueue });

      return this.newResourceReturnFactory.create<IUserModel>({
        response: response,
        data: response?.data?.response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<IUserModel>({
        response: e,
        data: {},
      });
    }
  }

  async deleteUser(userId: string) {
    try {
      const response = await this.api.get({
        route: `/users/${userId}`,
        headers: {
          Authorization: Cypress.env("SUPER_USER_TOKEN"),
        },
      });
      return response.data.message;
    } catch (e) {
      console.error(e);
    }
  }

  async create({
    name,
    motherName,
    cpf,
    email,
    birthdate,
    password,
    cellphone,
    genre,
    urlFrom,
    userType = "borrower",
    messageType = EMessageSendingType.SMS,
    maritalStatus,
    hasCalculatedTermination,
  }: UserCreateParams) {
    try {
      let payload = {
        name,
        mother_name: motherName,
        cpf,
        email,
        birthdate,
        password,
        cellphone,
        genre,
        url_from: urlFrom,
        user_type: userType,
        message_type: messageType,
        marital_status: maritalStatus,
        has_calculated_termination: hasCalculatedTermination,
      };

      payload = Object.fromEntries(Object.entries(payload).filter(([_, value]) => !!value));

      const response = await this.api.post({
        route: "/public/users",
        payload,
      });

      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response?.data?.response,
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }

  async adminUpdate({ userId, ...payload }: Partial<AdminUpdateParams>) {
    try {
      const response = await this.api.put({
        route: `/user/${userId}`,
        payload,
      });
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response?.data?.response,
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }

  async adminCreate({ motherName, urlFrom, messageType, ...payload }: UserCreateParams) {
    try {
      const response = await this.api.post({
        route: "/users",
        payload: {
          ...payload,
          mother_name: motherName,
          url_from: urlFrom,
          message_type: messageType || EMessageSendingType.SMS,
        },
      });
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response.data?.response || {},
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }

  async setAdditionalInformation({
    cellphone,
    marital_status,
    naturalness,
    phone,
  }: SetAdditionalInformationParams) {
    try {
      const response = await this.api.put({
        route: "/users",
        payload: { cellphone, marital_status, naturalness, phone },
      });

      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response?.data?.response,
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }

  async update({
    name,
    birthdate,
    genre,
    mother_name,
    phone,
    cellphone,
    naturalness,
    marital_status,
  }: Partial<UpdateParams>) {
    try {
      const response = await this.api.put({
        route: "/users",
        payload: {
          name,
          birthdate,
          genre,
          mother_name,
          phone,
          cellphone,
          naturalness,
          marital_status,
        },
      });

      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response?.data?.response,
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }

  async find({ ...params }) {
    try {
      const response = await this.api.get({ route: `/users/`, params: { ...params } });
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response?.data?.response,
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }

  async getUserAccounts() {
    try {
      const response = await this.api.get({ route: "/users/bank-accounts" });

      return this.newResourceReturnFactory.create<IUserBankAccount[]>({
        data: response?.data?.response.map((item: IUserBankAccount) => ({
          color: ColorGenerator.colorFromArray(),
          ...item,
        })),
        response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<IUserBankAccount[]>({
        data: [],
        response: e,
      });
    }
  }

  async adminFind({ userId }: AdminFindParams) {
    try {
      const response = await this.api.get({ route: `/users/${userId}/bank-accounts` });

      const data = Array.isArray(response.data.response)
        ? response.data.response.map((item: IUserBankAccount) => item)
        : [response.data.response];

      return this.newResourceReturnFactory.create<IUserBankAccount[]>({
        data,
        response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<IUserBankAccount[]>({
        data: [],
        response: e,
      });
    }
  }

  async allUsers() {
    try {
      const response = await this.api.get({ route: "/users" });

      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">[]>({
        data: response.data.map((item: Omit<IUserModel, "groups">) => item),
        response: response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">[]>({
        data: [],
        response: e,
      });
    }
  }

  async alreadyRegister({ uuid }: AlreadyRegisterParams) {
    try {
      const response = await this.api.get({
        route: `/public/users/${uuid}/registration-performed`,
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || false,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: false,
        },
      });
    }
  }

  async definePassword({ uuid, password }: DefinePasswordParams) {
    try {
      const response = await this.api.put({
        route: `public/users/${uuid}/define-password`,
        payload: { password },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async getUserByProposalId(proposalId: string | number) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/users` });
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: response.data.response,
        response,
      });
    } catch (e) {
      return this.newResourceReturnFactory.create<Omit<IUserModel, "groups">>({
        data: {},
        response: e,
      });
    }
  }
}
